<template>
  <div class="page">
    <div class="nav-bar">
      <div class="left" @click="goBack">
        <van-icon name="arrow-left" size="24" color="#fff" />
      </div>
      <div class="center">会员中心</div>
      <div class="right" @click="goRecord">记录</div>
    </div>
    <div class="nav-bar-block"></div>
    <div class="tabs">
      <div
        class="item"
        :class="currentTab === 0 ? 'current' : ''"
        @click="changeTab(0)"
      >
        会员
      </div>
      <div
        class="item"
        :class="currentTab === 1 ? 'current' : ''"
        @click="changeTab(1)"
      >
        增量包
      </div>
    </div>
    <div class="main" v-if="cardList.length">
      <div class="card-list">
        <div
          class="card"
          :class="currentCard.equitySetId === item.equitySetId ? 'current' : ''"
          v-for="item in cardList"
          :key="item.equitySetId"
          @click="selectCard(item)"
        >
          <div class="content" v-if="currentTab === 0">
            <div class="row-1">{{ item.equityName }}</div>
            <div class="row-2">
              <span style="font-size: 14px">￥</span
              >{{ parseFloat(item.equityPrice).toFixed(2) }}
            </div>
            <div class="row-3">
              ￥{{ parseFloat(item.equityOriginalPrice).toFixed(2) }}
            </div>
          </div>
          <div class="extra-content" v-if="currentTab === 1">
            <div class="row-1">{{ item.equityNum }}次</div>
            <div class="row-2">
              <span style="font-size: 14px">￥</span
              >{{ parseFloat(item.equityPrice).toFixed(2) }}
            </div>
          </div>
          <div class="bottom">有效期{{ item.equityTime }}天</div>
        </div>
      </div>
      <template v-if="currentTab === 0">
        <div class="title">权益说明</div>
        <div class="equity">
          <div class="header">
            <div class="item">权益</div>
            <div class="item">非会员</div>
            <div class="item">会员</div>
          </div>
          <div class="list">
            <div class="list-item">
              <div class="item-header">每日使用次数</div>
              <div class="line"></div>
              <div class="item">{{ equityData.useNumNow }}</div>
              <div class="line"></div>

              <div class="item">{{ equityData.memberUseNumNow }}</div>
            </div>
            <!-- <div class="list-item">
              <div class="item-header">每月使用次数</div>
              <div class="line"></div>
              <div class="item">{{ equityData.useNumMonth }}</div>
              <div class="line"></div>
              <div class="item">{{ equityData.memberUseNumMonth }}</div>
            </div> -->
          </div>
        </div>
      </template>

      <div class="title">规则说明</div>
      <div class="rule">
        <p style="white-space: pre-wrap;line-height:24px;letter-spacing:1px">
          {{ equityData.equityDec }}
        </p>
      </div>
      <div style="height: 100px"></div>
    </div>
    <div class="none" v-else>
      <img src="@assets/none.png" alt="" />
      <div class="text">现免费使用</div>
    </div>
    <div class="bottom-bar" v-if="cardList.length">
      <div
        class="btn"
        :class="currentCard.equitySetId ? '' : 'no'"
        @click="pay"
      >
        <span v-if="currentCard.equitySetId"
          ><span style="font-size: 14px">￥</span
          >{{ parseFloat(currentCard.equityPrice).toFixed(2) }}&nbsp;</span
        >开通
      </div>
    </div>
  </div>
</template>

<script setup>
import { getOpenMemberList } from './api.js';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { showDialog } from 'vant';
const router = useRouter();
const currentTab = ref(0);

onMounted(() => {
  getVipList();
  window.paySuccess = function () {
    showDialog({
      title: '提示',
      message: '支付成功',
    }).then(() => {
      // on close
    });
  };
});
function changeTab(item) {
  currentTab.value = item;
  currentCard.value = {};
  getVipList();
}

const cardList = ref([]);
const equityData = ref({});
function getVipList() {
  getOpenMemberList({ equityType: currentTab.value }).then((res) => {
    cardList.value = res.data.aiMemberOpenEquitySetListDTOList;
    equityData.value = res.data;
  });
}
const currentCard = ref({});
function selectCard(item) {
  currentCard.value = item;
}

function goBack() {
  router.push({
    name: 'home',
  });
}
function goRecord() {
  router.push({
    name: 'record',
  });
}

const payFlag = ref(false);
function pay() {
  if (payFlag.value) {
    return;
  }
  if (!currentCard.value.equitySetId) {
    return;
  }
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    android.toPay(currentCard.value.equitySetId, currentCard.value.equityPrice);
  }
  if (isiOS) {
    let paySend = {
      equitySetId: currentCard.value.equitySetId,
      equityPrice: currentCard.value.equityPrice,
      type: 'pay',
    };
    window.webkit.messageHandlers.AppModel.postMessage(paySend);
  }
  setTimeout(() => {
    payFlag.value = false;
  }, 500);
  payFlag.value = true;
}
</script>

<style lang="less" scoped>
@import url(./vip.less);
</style>