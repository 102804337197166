import { post } from '@/utils/http'
export const getOpenMemberList = function (data) {
    return post({
        url: "/app/xfai/getOpenMemberList",
        data
    })
}
export const getRecordList = function (data) {
    return post({
        url: "/app/xfai/getMemberRecord",
        data
    })
}

export const getMemberDetail = function (data) {
    return post({
        url: "/app/xfai/getMemberEquityList",
        data
    })
}


